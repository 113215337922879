













import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import Tax from '@/store/entities/preferences/tax';
import ListView from '@/components/helper/list-view.vue';
import ModalView from '@/components/helper/modal-view.vue';

class PageTaxRequest extends PageRequest { }

@Component({
    components: { ListView, ModalView }
})
export default class Taxes extends ListBase {
    pagerequest: PageTaxRequest = new PageTaxRequest();
    tax: Tax = new Tax();
    modalSetting: any = {
        entity: "tax",
        title: 'Impuesto',
        width: "500px"
    };

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    }
    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    }  
    items = [
        this.gItem({ type: 'object', prop: 'country_id', label: 'Pais', required: true, size: 6 }),
        this.gItem({ type: 'object', prop: 'behavior_id', label: 'Comportamiento', generalType: 'T_TAXES', size: 6 }),
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre', required: true, size: 6 }),
        this.gItem({ type: 'text', prop: 'rate', label: 'Tasa', prefix: '%', size: 6 }),
        this.gItem({ type: 'active', size: 6 })
    ]

    columns = [
        this.gCol('name', 'Nombre', 'text'),
        this.gColO({ type: 'percent', value: 'rate', text: 'Tasa' }),
        this.gCol('active', 'Activo', 'boolean'),
        this.defaultActions()
    ]

    async created() {
        //this.getpage();
    }
}